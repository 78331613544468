<template>
  <div>
    <div class="mx-auto mt-10" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: 'UptoDateGrade',
  data () {
    return {
      content: ''
    }
  },
  created () {
    this.handleGetGrade()
  },
  methods: {
    async handleGetGrade () {
      if (!this.$route.params.id) return

      try {
        const params = {
          id: this.$route.params.id
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getUptodateGrade(params)
        this.content = response.data.data?.gradeHtml
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>